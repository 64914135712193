<template lang='pug'>
  div
    .text-xl.font-bold Список стран
    .text-sm Вы можете просмотреть и настроить страны, в том числе их телефонные номера и кей-коды

    template(v-if='countries')
      p-table(
        :data='countries'
        :search='true'
        :headers='{ "dialling_code": "Номер телефона", "country_name": "Название страны", "country_code": "Код страны",  }'
        :actions='{ edit: editCountryStart }'
      )
    template(v-else)
      .text-sm.opacity-20.mt-5 Идёт загрузка данных...
</template>

<script>
import { getCountries, editCountry } from './../assets/api.js';

export default {
  mounted() {
    this.loadData();
  },
  data() {
    return {
      countries: null
    }
  },
  methods: {
    editCountryStart(country) {
      this.$root.openDialog({
        component: {
          is: () => import('./../components/dialogs/Country.vue'),
          country: country,
          onSave: this.editCountryFinish
        }
      });
    },

    async editCountryFinish(country) {
      const result = await editCountry(country);
      if (!result.success) {
        console.error('Не удалось отредактировать страну', result.data);
        return;
      }

      console.log('Страна отредактирована');
      this.loadData();
    },

    deleteCountryStart(country) {

    },

    deleteCountryFinish(country) {

    },

    /**
     * Загрузить данные о странах из БД
     */
    async loadData() {
      const result = await getCountries();
      if (!result.success) {
        console.error('Ошибка получения списка стран');
        return;
      }

      this.countries = result.data;
    }
  }
}
</script>

<style>

</style>